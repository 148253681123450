import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "Authentication",
      checkLogin: true,
    },
  },
  {
    path: "/dashboard",
    component: () => import("@/views/Dashboard.vue"),
    children: [
      {
        path: "",
        name: "dashboard",
        meta: {
          authorizedRoles: [1, 2],
        },
        component: () => import("@/views/DashboardIndex.vue"),
      },{
        path: "developer",
        component: () => import("@/views/Developer.vue"),
        children: [
          {
            path: "",
            name: "developer",
            meta: {
              authorizedRoles: [1],
            },
            component: () => import("@/views/DeveloperIndex.vue"),
          },
          {
            path: "create/",
            name: "developer-create",  
            meta: {
              authorizedRoles: [1],
            },
            component: () => import("@/views/DeveloperCreate.vue"),
          },
          {
            path: "edit/:id",
            name: "developer-edit",  
            meta: {
              authorizedRoles: [1],
            },
            component: () => import("@/views/DeveloperEdit.vue"),
          },
        ],
      },
      {
        path: "project",
        component: () => import("@/views/Project.vue"),
        children: [
          {
            path: "",
            name: "project",
            meta: {
              authorizedRoles: [1, 2],
            },
            component: () => import("@/views/ProjectIndex.vue"),
          },
          {
            path: "create/",
            name: "project-create",  
            meta: {
              authorizedRoles: [1, 2],
            },
            component: () => import("@/views/ProjectCreate.vue"),
          },
          {
            path: "edit/:id",
            name: "project-edit",  
            meta: {
              authorizedRoles: [1, 2],
            },
            component: () => import("@/views/ProjectEdit.vue"),
          },
        ],
      },
      {
        path: "property-detail",
        component: () => import("@/views/PropertyDetail.vue"),
        children: [
          {
            path: "",
            name: "property-detail",
            meta: {
              authorizedRoles: [1, 2],
            },
            component: () => import("@/views/PropertyDetailIndex.vue"),
          },
          {
            path: "create/",
            name: "property-detail-create",  
            meta: {
              authorizedRoles: [1, 2],
            },
            component: () => import("@/views/PropertyDetailCreate.vue"),
          },
          {
            path: "edit/:id",
            name: "property-detail-edit",  
            meta: {
              authorizedRoles: [1, 2],
            },
            component: () => import("@/views/PropertyDetailEdit.vue"),
          },
        ],
      },
      {
        path: "photo-detail-property",
        component: () => import("@/views/PhotoProperty.vue"),
        children: [
          {
            path: "",
            name: "photo-detail-property",
            meta: {
              authorizedRoles: [1, 2],
            },
            component: () => import("@/views/PhotoPropertyIndex.vue"),
          },
          {
            path: "create/",
            name: "photo-detail-property-create",  
            meta: {
              authorizedRoles: [1, 2],
            },
            component: () => import("@/views/PhotoPropertyCreate.vue"),
          },
        ],
      },
      {
        path: "tracking",
        component: () => import("@/views/Tracking.vue"),
        children: [
          {
            path: "",
            name: "tracking",
            meta: {
              authorizedRoles: [1],
            },
            component: () => import("@/views/TrackingIndex.vue"),
          },
          {
            path: "detail/:id",
            name: "tracking-detail",  
            meta: {
              authorizedRoles: [1],
            },
            component: () => import("@/views/TrackingDetail.vue"),
          },
        ],
      },
      {
        path: "user",
        component: () => import("@/views/User.vue"),
        children: [
          {
            path: "profile",
            name: "user-profile",
            meta: {
              authorizedRoles: [2],
            },
            component: () => import("@/views/UserProfile.vue"),
          },
        ],
      },
      {
        path: "booth",
        component: () => import("@/views/Booth.vue"),
        children: [
          {
            path: "",
            name: "booth",
            meta: {
              authorizedRoles: [2],
            },
            component: () => import("@/views/BoothIndex.vue"),
          },
          {
            path: "edit/:id",
            name: "booth-edit",  
            meta: {
              authorizedRoles: [2],
            },
            component: () => import("@/views/BoothEdit.vue"),
          },
        ],
      },
      {
        path: "booth-coordinate",
        component: () => import("@/views/BoothCoordinate.vue"),
        children: [
          {
            path: "",
            name: "booth-coordinate",
            meta: {
              authorizedRoles: [1],
            },
            component: () => import("@/views/BoothCoordinateIndex.vue"),
          },
          {
            path: "create",
            name: "booth-coordinate-create",
            meta: {
              authorizedRoles: [1],
            },
            component: () => import("@/views/BoothCoordinateCreate.vue"),
          },
          {
            path: "edit/:id",
            name: "booth-coordinate-edit",  
            meta: {
              authorizedRoles: [1],
            },
            component: () => import("@/views/BoothCoordinateEdit.vue"),
          },
        ],
      },
      {
        path: "visitor",
        component: () => import("@/views/Visitor.vue"),
        children: [
          {
            path: "",
            name: "visitor",
            meta: {
              authorizedRoles: [1],
            },
            component: () => import("@/views/VisitorIndex.vue"),
          },
          {
            path: "detail/:id",
            name: "visitor-detail",  
            meta: {
              authorizedRoles: [1],
            },
            component: () => import("@/views/VisitorDetail.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "*",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { authorizedRoles, checkLogin } = to.meta;
  const { token, profile } = store.state.auth;
 
  // check if meta exist
  if (authorizedRoles) {
    // check if user logged in
    if (token === "") {
      next("/");
    }

    if (authorizedRoles.indexOf(parseInt(profile.role_id)) === -1) {
      next(from.path);
    } else {
      next();
    }
  } else {
    next();
  }

  if (checkLogin) {
    if (token !== "") {
      next("/dashboard");
    }
  } else {
    next();
  }
});

export default router;
