export const SET_AUTHENTICATION = (state, payload) => {
  state.token = payload.token;
  state.profile = payload.profile;
};

export const SET_PROFILE = (state, payload) => {
  state.profile = payload.profile;
};

export const REMOVE_TOKEN = function (state) {
  state.token = ''
}

export const REMOVE_PROFILE = function (state) {
  state.profile = {}
}

export const SET_LOADING = function (state) {
  state.loading = !state.loading
}

