import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Layout from "./layouts/Layout";
import './plugins/tiptap-vuetify'
import './index.css';
import "tailwindcss/tailwind.css";
import moment from 'moment'
import VueNumberInput from '@chenfengyuan/vue-number-input';

Vue.prototype.moment = moment

Vue.component("Layout", Layout);
Vue.use(VueNumberInput)

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
